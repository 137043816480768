import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterDropdown'
})
export class FilterDropdownPipe implements PipeTransform {

  transform(data: any[], searchText: string): any[] {
    if (!searchText || searchText === null || searchText === undefined) {
      return data;
    }
    searchText = searchText.toString().toLowerCase();
    if(data && data[0].name) {
      return data.filter((ele) => ele?.name.toLowerCase().includes(searchText));
    } else {
    return data.filter((ele) => ele?.toLowerCase().includes(searchText));
    }
  }

}